const TabNames = {
  HOME: "Home",
  CORRELATION: "Correlation",
  PCA: "PCA",
  DR: "DR & Clustering",
  MDE: "MDE",
  UMAP: "UMAP",
  TSNE: "tSNE",
  GENE_REGULATION: "Gene-Regulation",
  HEATMAP: "HeatMap",
  PATHFINDER: "Path Explorer",
  GENESIGNATURE: "Gene Signature",
  EXPRESSIONANALYZER: "Expression Analyzer",
  GENELISTCOMPARE: "Genelists",
  ABOUT: "Help",
};

export { TabNames };
